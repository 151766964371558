import { LinkedinFilled, MailFilled, MailOutlined } from "@ant-design/icons";
import { Badge, Card, Space, Typography } from "antd";
import { Link } from "react-router-dom";
import ViewMoreTeam from "../../modals/ViewMoreTeam";
import ViewProfile from "./ViewProfile";
import { FaViber } from "react-icons/fa6";

interface CardInfo {
  id: number;
  position: string;
  name: string;
  department: string;
}

const Management = () => {
  const card: CardInfo[] = [
    {
      id: 1,
      position: "CEO",
      name: "Whitney Francis",
      department: "",
    },
    {
      id: 2,
      position: "COO",
      name: "John Doe",
      department: "",
    },
    {
      id: 3,
      position: "General Manager",
      name: "Francis Magalona",
      department: "",
    },
    {
      id: 4,
      position: "Head Of HR",
      name: "Anne Go",
      department: "HR",
    },
    {
      id: 5,
      position: "Head of Finance",
      name: "Sherry Sy",
      department: "Finance",
    },
    {
      id: 6,
      position: "Head of Director",
      name: "Albert Go",
      department: "Director",
    },
    {
      id: 7,
      position: "Head of Sales",
      name: "Abet Lou",
      department: "Sales",
    },
    {
      id: 8,
      position: "Head of Products",
      name: "Kevin Cruz",
      department: "Products",
    },
    {
      id: 9,
      position: "Content Marketing",
      name: "Pokoy Kie",
      department: "Marketing",
    },
    {
      id: 10,
      position: "Marketing Executive",
      name: "Alben Burja",
      department: "Marketing Executive",
    },
    {
      id: 11,
      position: "Social Marketing",
      name: "Kokoy Bugoy",
      department: "Social Marketing",
    },
  ];
  const { Text, Paragraph } = Typography;

  const selectFirstTwo = card.filter((_, index) => index < 3);
  const selectTwoLast = card.filter((_, index) => index > 2);

  return (
    <>
      <div className=" flex flex-col items-center justify-center mt-16 mb-12 space-y-3">
        <Text className="font-sans text-3xl font-bold">Management Team</Text>
        <Paragraph className=" font-sans text-lg text-neutral-500">
          Hi, we are the Management Team! Here to drive innovation, growth, and
          success every step of the way.
        </Paragraph>
      </div>
      <div className="grid grid-flow-row grid-cols-3 gap-10 justify-center items-center">
        {selectFirstTwo.map(({ id, position, name }) => (
          <Badge.Ribbon text="Hello" key={id}>
            <Card hoverable className=" border border-neutral-200">
              <Space className=" flex flex-col items-center justify-center">
                <img
                  src="../images/prof.jpg"
                  alt="prof"
                  className="aspect-square object-cover shadow-xl shadow-blue-300 w-28 rounded-full"
                />
                <div className="flex flex-col justify-center items-center">
                  <p className=" font-sans text-lg text-[#0056D2] font-bold leading-tight mt-8">
                    <Link to="/index/my-detailed-profile" state={{ name }}>
                      {name}
                    </Link>
                  </p>
                  <p className=" font-sans text-sm leading-tight font-medium text-neutral-600 mt-1">
                    {position}
                  </p>
                </div>
                <div className=" flex mt-5 bg-white px-2 space-x-3">
                  <Typography.Link href="#">
                    <FaViber className=" text-xl text-[#0056D2]" />
                  </Typography.Link>
                  <Typography.Link href="#">
                    <MailOutlined className=" text-xl text-[#0056D2]" />
                  </Typography.Link>
                </div>
              </Space>
            </Card>
          </Badge.Ribbon>
        ))}
      </div>
      <div className="grid grid-flow-row grid-cols-4 mt-12 gap-10">
        {selectTwoLast.map(({ id, position, name, department }) => (
          <Card hoverable className=" border border-neutral-200" key={id}>
            <div className="flex flex-col justify-center items-center mb-3">
              <img
                src="../images/prof.jpg"
                alt="prof"
                className="aspect-square object-cover shadow-xl shadow-blue-300 w-28 rounded-full"
              />
              <p className=" font-sans text-lg text-[#0056D2] font-bold leading-tight mt-8">
                <Link to="/index/my-detailed-profile" state={{ name }}>
                  {name}
                </Link>
              </p>
              <p className=" font-sans text-sm leading-tight font-medium text-neutral-600 mt-1">
                {position}
              </p>
              <div className=" flex items-center justify-center space-x-3 mt-7">
                <Typography.Link href="#">
                  <FaViber className=" text-xl text-[#0056D2]" />
                </Typography.Link>
                <Typography.Link href="#">
                  <MailOutlined className=" text-xl text-[#0056D2]" />
                </Typography.Link>
              </div>
            </div>
            <div className="absolute flex justify-center items-center left-0 right-0 bottom-[-15px]">
              <ViewMoreTeam id={id} department={department} />
            </div>
          </Card>
        ))}
      </div>
    </>
  );
};

export default Management;
