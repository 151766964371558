import React, { useState } from "react";
import { Button, Modal, Steps, Typography, ConfigProvider } from "antd";
import AddEmployeeGeneral from "../forms/AddEmployeeGeneral";
import AddNewEmployeeEducation from "../forms/AddNewEmployeeEducation";
import AddNewEmployeeGovernment from "../forms/AddNewEmployeeGovernment";
import AddNewEmployeeInformation from "../forms/AddNewEmployeeInformation";
import AddNewEmployeeCompensation from "../forms/AddNewEmployeeCompensation";

interface StepsTypes {
  title: JSX.Element;
  content: JSX.Element;
}

const AddNewEmployee: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [current, setCurrent] = useState<number>(0);

  const { Text } = Typography;

  // toggle modal
  const showModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  // handle next step
  const handleNext = () => {
    setCurrent(current + 1);
  };

  // handle previous step
  const handlePrevious = () => {
    setCurrent(current - 1);
  };

  // array of step
  const steps: StepsTypes[] = [
    {
      title: <Text className=" font-sans text-sm font-bold">General</Text>,
      content: <AddEmployeeGeneral />,
    },
    {
      title: <Text className=" font-sans text-sm font-bold">Education</Text>,
      content: <AddNewEmployeeEducation />,
    },
    {
      title: (
        <Text className=" font-sans text-sm font-bold">
          Government ID Numbers
        </Text>
      ),
      content: <AddNewEmployeeGovernment />,
    },
    {
      title: (
        <Text className=" font-sans text-sm font-bold">
          Employment Information
        </Text>
      ),
      content: <AddNewEmployeeInformation />,
    },
    {
      title: <Text className=" font-sans text-sm font-bold">Compensation</Text>,
      content: <AddNewEmployeeCompensation />,
    },
  ];

  // map the jsx component
  const contentData = steps.map((entry, index) => ({
    key: index,
    title: entry.title,
    content: entry.content,
  }));

  return (
    <>
      <Button type="primary" className="font-sans" onClick={showModal}>
        Add Employee
      </Button>
      <Modal
        title={
          <Text className="font-sans text-3xl font-bold">Create Profile</Text>
        }
        width={1350}
        open={isModalOpen}
        onCancel={showModal}
        footer={[
          <Button
            key="close"
            type="text"
            className="font-sans"
            onClick={showModal}
          >
            Close
          </Button>,
          <ConfigProvider
            key="save-close"
            theme={{ token: { colorPrimary: "#ea580c" } }}
          >
            <Button type="text" className="font-sans bgo" onClick={showModal}>
              Save and Close
            </Button>
          </ConfigProvider>,
          // conditional rendering based on step progress
          current > 0 && (
            <Button
              key="previous"
              type="default"
              className="font-sans"
              onClick={handlePrevious}
            >
              Previous
            </Button>
          ),
          current < steps.length - 1 && (
            <Button
              key="continue"
              type="primary"
              className="font-sans"
              onClick={handleNext}
            >
              Continue
            </Button>
          ),
          current === steps.length - 1 && (
            <Button key="submit" type="primary" className="font-sans">
              Submit
            </Button>
          ),
        ]}
      >
        <div>
          {/* <Steps
            current={current}
            items={contentData.map((entry) => ({ key: entry.key }))}
            className="mt-5"
          /> */}
          <Steps
            current={current}
            items={contentData.map((item) => ({
              key: item.key,
              title: item.title,
            }))}
            className="mt-5"
          />

          <div>{contentData[current].content}</div>
        </div>
      </Modal>
    </>
  );
};

export default AddNewEmployee;
