import { Input, Select, Space, Typography } from "antd";
import React from "react";

const AddNewEmployeeInformation = () => {
  const { Text } = Typography;
  return (
    <>
      <Space className="block mt-10 mb-8 space-y-5 mx-16">
        <Text className=" font-sans text-2xl font-bold text-[#0056D2]">
          Employment Details
        </Text>
        <Space className=" grid grid-flow-row grid-cols-3 gap-10">
          <div className="flex flex-col">
            <Text className="font-sans">Start Date</Text>
            <Input name="age" type="date" className="custom-style-input mt-2" />
          </div>
          <div className="flex flex-col">
            <Text className="font-sans">End Date</Text>
            <Input name="age" type="date" className="custom-style-input mt-2" />
          </div>
        </Space>
        <Space className="grid grid-flow-row grid-cols-3 gap-10 mb-10">
          <div className="flex flex-col">
            <Text className="font-sans">Position</Text>
            <Select
              placeholder=" Select Degree"
              className=" font-sans text-sm mt-2"
              options={[
                { value: 1, label: "Bachelor`s" },
                { value: 2, label: "Doctor" },
              ]}
            />
          </div>
          <div className="flex flex-col">
            <Text className="font-sans">Level</Text>
            <Select
              placeholder=" Select Degree"
              className=" font-sans text-sm mt-2"
              options={[
                { value: 1, label: "Bachelor`s" },
                { value: 2, label: "Doctor" },
              ]}
            />
          </div>
          <div className="flex flex-col">
            <Text className="font-sans">Department</Text>
            <Select
              placeholder=" Select Degree"
              className=" font-sans text-sm mt-2"
              options={[
                { value: 1, label: "Bachelor`s" },
                { value: 2, label: "Doctor" },
              ]}
            />
          </div>
          <div className="flex flex-col">
            <Text className="font-sans">Employment Status</Text>
            <Select
              placeholder=" Select Degree"
              className=" font-sans text-sm mt-2"
              options={[
                { value: 1, label: "Bachelor`s" },
                { value: 2, label: "Doctor" },
              ]}
            />
          </div>
          <div className="flex flex-col">
            <Text className="font-sans">Manager</Text>
            <Input name="age" className="custom-style-input mt-2" />
          </div>
          <div className="flex flex-col">
            <Text className="font-sans">Work Arrangement</Text>
            <Input name="age" className="custom-style-input mt-2" />
          </div>
        </Space>
        <Text className=" font-sans text-2xl font-bold text-[#0056D2]">
          Benefits
        </Text>
        <div className=" flex flex-col">
          <Text className="font-sans">Health card</Text>
          <Space className=" space-x-5">
            <Input
              name="age"
              placeholder="amount"
              style={{ width: 150 }}
              className="custom-style-input mt-2"
            />
            <Input
              name="age"
              style={{ width: 250 }}
              placeholder="Health Card Number"
              className="custom-style-input mt-2"
            />

            <Input
              name="age"
              type="date"
              style={{ width: 250 }}
              className="custom-style-input mt-2"
            />
          </Space>
        </div>
        <div className="flex flex-col">
          <Text className="font-sans">Rent Allowance</Text>
          <Space className=" space-x-5">
            <Input
              name="age"
              placeholder="amount"
              style={{ width: 150 }}
              className="custom-style-input mt-2"
            />
            <Select
              style={{ width: 250 }}
              placeholder="Term"
              className=" font-sans text-sm mt-2"
              options={[
                { value: 1, label: "Month" },
                { value: 2, label: "Year" },
              ]}
            />
          </Space>
        </div>
        <div className="flex flex-col">
          <Text className="font-sans">Telecommunication</Text>
          <Space className=" space-x-5">
            <Input
              name="age"
              placeholder="amount"
              style={{ width: 150 }}
              className="custom-style-input mt-2"
            />
            <Select
              style={{ width: 250 }}
              placeholder="Term"
              className=" font-sans text-sm mt-2"
              options={[
                { value: 1, label: "Month" },
                { value: 2, label: "Year" },
              ]}
            />
          </Space>
        </div>
        <div className="flex flex-col">
          <Text className="font-sans">Transportation Allowance</Text>
          <Space className=" space-x-5">
            <Input
              name="age"
              placeholder="amount"
              style={{ width: 150 }}
              className="custom-style-input mt-2"
            />
            <Select
              style={{ width: 250 }}
              placeholder="Term"
              className=" font-sans text-sm mt-2"
              options={[
                { value: 1, label: "Month" },
                { value: 2, label: "Year" },
              ]}
            />
          </Space>
        </div>
      </Space>
    </>
  );
};

export default AddNewEmployeeInformation;
