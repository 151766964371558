import {
  Button,
  Card,
  ConfigProvider,
  Input,
  Pagination,
  Select,
  Space,
  Tag,
  Typography,
} from "antd";
import { BsSearch } from "react-icons/bs";
import { PiTreeView } from "react-icons/pi";
import "../../App.css";
import { MailOutlined } from "@ant-design/icons";
import { FaViber } from "react-icons/fa6";
import { Link } from "react-router-dom";

interface CardInfo {
  id: number;
  position: string;
  name: string;
  department: string;
  status: number;
}

function Directory() {
  const { Text, Paragraph } = Typography;
  const card: CardInfo[] = [
    {
      id: 1,
      position: "CEO",
      name: "Whitney Francis",
      department: "",
      status: 1,
    },
    {
      id: 2,
      position: "COO",
      name: "John Doe",
      department: "",
      status: 1,
    },
    {
      id: 3,
      position: "Head Of HR",
      name: "Anne Go",
      department: "HR",
      status: 1,
    },
    {
      id: 4,
      position: "Head of Finance",
      name: "Sherry Sy",
      department: "Finance",
      status: 1,
    },
    {
      id: 5,
      position: "Head of Director",
      name: "Albert Go",
      department: "Director",
      status: 1,
    },
    {
      id: 6,
      position: "Head of Sales",
      name: "Abet Lou",
      department: "Sales",
      status: 3,
    },
    {
      id: 7,
      position: "Head of Products",
      name: "Kevin Cruz",
      department: "Products",
      status: 1,
    },
    {
      id: 8,
      position: "Content Marketing",
      name: "Pokoy Kie",
      department: "Marketing",
      status: 1,
    },
    {
      id: 9,
      position: "Marketing Executive",
      name: "Alben Burja",
      department: "Marketing Executive",
      status: 3,
    },
    {
      id: 10,
      position: "Social Marketing",
      name: "Kokoy Bugoy",
      department: "Social Marketing",
      status: 2,
    },
  ];

  const statusList = [
    {
      id: 1,
      label: "Active",
      color: "text-green-600 font-sans",
    },
    {
      id: 2,
      label: "Inactive",
      color: "text-neutral-400 font-sans",
    },
    {
      id: 3,
      label: "In-progress",
      color: "text-blue-600 font-sans",
    },
  ];

  return (
    <>
      <div className="flex flex-col justify-center items-center mt-10 mb-12 space-y-3">
        <Text className="font-sans text-3xl font-bold">Team Members</Text>
        <Paragraph className=" font-sans text-lg text-neutral-500">
          Hi, we are the Management Team! Here to drive innovation, growth, and
          success every step of the way.
        </Paragraph>
      </div>
      <div className=" flex flex-col my-5 space-y-6">
        <div className=" flex items-center mt-7 space-x-5">
          <Input
            placeholder="Search.."
            className="custom-style-input w-72 bgcy"
            suffix={<BsSearch />}
          />
          <Select
            defaultValue="All Office"
            className=" font-sans w-40"
            options={[
              { value: "jack", label: "Jack" },
              { value: "All Office", label: "All Office", disabled: true },
            ]}
          />
          <Select
            defaultValue="All Status"
            className=" font-sans w-40"
            options={[
              { value: "jack", label: "Jack" },
              {
                value: "All Status",
                label: "All Status",
                disabled: true,
              },
            ]}
          />
          <ConfigProvider
            theme={{
              token: { colorPrimary: "#fb923c", colorBorder: "#ea580c" },
            }}
          >
            <Button
              type="dashed"
              className=" rounded-md font-sans text-orange-500"
              icon={<PiTreeView className=" font-bold" />}
            >
              Clear Filter
            </Button>
          </ConfigProvider>
        </div>
        <div className="grid grid-cols-5 grid-flow-row gap-7">
          {card.map(({ position, name, status }, index) => {
            const statusColor = statusList.find((entry) => entry.id === status);
            return (
              <Card
                hoverable
                className=" border border-neutral-200"
                key={index}
              >
                <div className=" flex flex-col items-center justify-center">
                  <img
                    src="../images/prof.jpg"
                    alt="prof"
                    className="aspect-square object-cover shadow-xl shadow-blue-300 w-28 rounded-full"
                  />
                  <div className="flex flex-col justify-center items-center">
                    <p className=" font-sans text-lg text-[#0056D2] text-center font-bold leading-tight mt-8">
                      <Link to="/index/my-detailed-profile" state={{ name }}>
                        {name}
                      </Link>
                    </p>
                    <p className=" font-sans text-sm text-left leading-tight font-medium text-neutral-600 mt-1 mb-2">
                      {position}
                    </p>
                    <Tag className={statusColor?.color}>
                      {statusColor?.label}
                    </Tag>
                  </div>
                  <div className=" flex mt-5 bg-white space-x-3">
                    <Typography.Link href="#">
                      <FaViber className=" text-xl text-[#0056D2]" />
                    </Typography.Link>
                    <Typography.Link href="#">
                      <MailOutlined className=" text-xl text-[#0056D2]" />
                    </Typography.Link>
                  </div>
                </div>
              </Card>
            );
          })}
        </div>
        <Pagination
          className=" flex justify-end"
          // current={1}
          defaultPageSize={10}
        />
      </div>
    </>
  );
}

export default Directory;
