// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  padding: 0;
  margin: 0;
}

.child {
  display: none;
  transition: opacity 0.3s ease-in;
}
.parent:hover .child {
  display: block;
}
body {
  background-color: #fafafbff;
  font-family: "Roboto", sans-serif;
}
.custom-style-input ::placeholder {
  --tw-text-opacity: 1;
  font-family: "Roboto", sans-serif;
  color: #969da7;
  font-size: small;
}

/* Page css */

/* @page {
    size: A4;
    margin: 0;
  } */
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,SAAS;AACX;;AAEA;EACE,aAAa;EACb,gCAAgC;AAClC;AACA;EACE,cAAc;AAChB;AACA;EACE,2BAA2B;EAC3B,iCAAiC;AACnC;AACA;EACE,oBAAoB;EACpB,iCAAiC;EACjC,cAAc;EACd,gBAAgB;AAClB;;AAIA,aAAa;;AAEb;;;KAGK","sourcesContent":["* {\n  padding: 0;\n  margin: 0;\n}\n\n.child {\n  display: none;\n  transition: opacity 0.3s ease-in;\n}\n.parent:hover .child {\n  display: block;\n}\nbody {\n  background-color: #fafafbff;\n  font-family: \"Roboto\", sans-serif;\n}\n.custom-style-input ::placeholder {\n  --tw-text-opacity: 1;\n  font-family: \"Roboto\", sans-serif;\n  color: #969da7;\n  font-size: small;\n}\n\n@import url(\"https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap\");\n\n/* Page css */\n\n/* @page {\n    size: A4;\n    margin: 0;\n  } */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
